import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Overhead Squats 2-2-2-2-2 to a 2RM`}</p>
    <p>{`then,`}</p>
    <p>{`10:00 AMRAP of:`}</p>
    <p>{`1-Ring Muscle Up`}</p>
    <p>{`2-Full Snatch (115/75) (RX+ 135/95)`}</p>
    <p>{`10-Wall Balls (20/14)`}</p>
    <p>{`2-Ring Muscle Ups`}</p>
    <p>{`3-Full Snatch`}</p>
    <p>{`12-Wall Balls`}</p>
    <p>{`3-Ring Muscle Ups`}</p>
    <p>{`4-Full Snatch`}</p>
    <p>{`14-Wall Balls`}</p>
    <p>{`4, 5, 6 etc Muscle Ups`}</p>
    <p>{`5, 6, 7 etc Full Snatch`}</p>
    <p>{`16, 18, 20 etc Wall Balls`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      